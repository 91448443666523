<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.code')"
                :placeholder="$t('labels.code')"
                name="code"
                sort-name="code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectCity
                class="c-input-xs input-filter"
                name="id_city"
                :label="$t('labels.city')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectCounty
                class="c-input-xs input-filter"
                name="id_county"
                :id-city="filters.id_city"
                :label="$t('labels.district')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectWard
                class="c-input-xs input-filter"
                name="id_ward"
                :id-county="filters.id_county"
                :id-city="filters.id_city"
                :label="$t('labels.ward')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.address')"
                :placeholder="$t('labels.address')"
                name="address"
                sort-name="address"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.phone_number')"
                :placeholder="$t('labels.phone_number')"
                name="phone"
                sort-name="phone"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.latitude')"
                :placeholder="$t('labels.latitude')"
                name="latitude"
                sort-name="latitude"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.longitude')"
                :placeholder="$t('labels.longitude')"
                name="longitude"
                sort-name="longitude"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn
                color="primary"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td class="">
              <span v-if="!item.editing">{{ item.code }}</span>
              <v-text-field
                v-else
                v-model="item.code"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.code')"
              ></v-text-field>
            </td>
            <td class="">
              <span v-if="!item.editing">{{ item.name }}</span>
              <v-text-field
                v-else
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.name')"
              ></v-text-field>
            </td>
            <td class="">
              <span v-if="!item.editing" class="text-capitalize">{{
                item.city_name
              }}</span>
              <SelectCity
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :label="$t('labels.city')"
                name="id_city"
                @onChange="onItemChange"
              />
            </td>
            <td class="">
              <span v-if="!item.editing" class="text-capitalize">{{
                item.county_name
              }}</span>
              <SelectCounty
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :id-county="item.id_county"
                :label="$t('labels.district')"
                name="id_county"
                @onChange="onItemChange"
              />
            </td>
            <td class="">
              <span v-if="!item.editing" class="text-capitalize">{{
                item.ward_name
              }}</span>
              <SelectWard
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :id-county="item.id_county"
                :id-ward="item.id_ward"
                :label="$t('labels.ward')"
                name="id_ward"
                @onChange="onItemChange"
              />
            </td>
            <td class="">
              <span v-if="!item.editing">{{ item.address }}</span>
              <v-text-field
                v-else
                v-model="item.address"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.address')"
              ></v-text-field>
            </td>
            <td class="">
              <span v-if="!item.editing">{{ item.phone }}</span>
              <v-text-field
                v-else
                v-model="item.phone"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.phone_number')"
              ></v-text-field>
            </td>
            <td class="">
              <span v-if="!item.editing">{{ item.latitude }}</span>
              <v-text-field
                v-else
                v-model.number="item.latitude"
                :disabled="!item.editing"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.latitude')"
              ></v-text-field>
            </td>
            <td class="">
              <span v-if="!item.editing">{{ item.longitude }}</span>
              <v-text-field
                v-else
                v-model.number="item.longitude"
                :disabled="!item.editing"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.longitude')"
              ></v-text-field>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  class="mt-0 ml-1"
                  :value="1"
                  :disabled="!item.editing"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                  style="width: 70px"
                  class="mb-1"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="purple"
                  outlined
                  @click="showWorkingTimeDialog(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.set_working_time") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                  style="width: 70px"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>

    <v-dialog v-model="dialogWorkingTime" persistent max-width="600px">
      <OfficeWorkingTime
        v-if="dialogWorkingTime"
        :item="updatingItem"
        @cancel="hideWorkingTimeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { STATUS_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "Index",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    OfficeWorkingTime: () => import("@/components/office/OfficeWorkingTime"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    warehouse: {},
    detailDialog: false,
    isLoading: false,
    statusOptions: [...STATUS_OPTIONS],
    updatingItem: {},
    dialogWorkingTime: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        code: null,
        id_city: null,
        id_county: null,
        id_ward: null,
        address: null,
        phone: null,
        latitude: null,
        longitude: null,
        status: 1,
        editing: true,
      });
    },
    onItemChange(val) {
      const items = [...this.items];
      let item = [...items].find((i) => i.id === val.id);
      if (item) {
        const itemIndex = [...items].findIndex((i) => i.id === val.id);
        item = { ...item, [val.name]: val.value };
        items[itemIndex] = item;
        this.items = [...items];
      }
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/office-save", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/office-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },

    showWorkingTimeDialog(item) {
      this.updatingItem = { ...item };
      this.dialogWorkingTime = true;
    },
    hideWorkingTimeDialog() {
      this.updatingItem = {};
      this.dialogWorkingTime = false;
    },
  },
};
</script>

<style scoped></style>
